'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.MenuFixedNav = function MenuFixedNav() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $navLinks = [].slice.call(document.querySelectorAll('.fixed-nav-link'));

    if ($navLinks) {

      $navLinks.forEach(function ($navLink) {

        $navLink.addEventListener('click', function (e) {
          e.preventDefault();

          var $currentBlock = document.querySelector($navLink.getAttribute('href'));
          var $sections = [].slice.call(document.querySelectorAll('.menu-blocks'));

          for (i = 0; i < $navLinks.length; i++) {
            $navLinks[i].classList.remove('is-active');
            $sections[i].classList.remove('is-active');
          }

          $currentBlock.classList.add('is-active');
          $navLink.classList.add('is-active');
        });
      });
    };

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();