'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Split001 = function Split001() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $dragItems = [].slice.call(document.querySelectorAll('.is-slider'));

    $dragItems.forEach(function ($dragItem) {
      addEvent('mousedown', $dragItem, onMouseDown);
      addEvent('mousemove', $dragItem, onMouseMove);
      addEvent('mouseUp', $dragItem, onMouseUp);
    });

    return true;
  };

  var addEvent = function addEvent(name, el, func) {
    if (el.addEventListener) {
      el.addEventListener(name, func, false);
    } else if (el.attachEvent) {
      el.attachEvent('on' + name, func);
    } else {
      el[name] = func;
    }
  };

  var onMouseDown = function onMouseDown(e) {
    console.log('[Split001] : Mouse down triggered');

    if (!e) {
      e = window.event;
    }
    if (e.target && e.target.nodeName === 'IMG') {
      e.preventDefault();
    } else if (e.srcElement && e.srcElement.nodeName === 'IMG') {
      e.returnValue = false;
    }
    startx = e.clientX + e.scrollLeft;
    starty = e.clientY + e.scrollTop;
    diffx = 0;
    diffy = 0;
    drag = true;
  };

  var onMouseMove = function onMouseMove(e) {
    console.log('[Split001] : Mouse move triggered');
    if (drag === true) {
      if (!e) {
        e = window.event;
      }
      diffx = this.startx - (e.clientX + e.scrollLeft);
      diffy = this.starty - (e.clientY + e.scrollTop);
      e.scrollLeft += diffx;
      e.scrollTop += diffy;
    }
  };

  var onMouseUp = function onMouseUp(e) {
    if (!e) {
      e = window.event;
    }
    drag = false;
    var start = 1,
        animate = function animate() {
      var step = Math.sin(start);
      if (step <= 0) {
        window.cancelAnimationFrame(animate);
      } else {
        e.scrollLeft += diffx * step;
        e.scrollTop += diffy * step;
        start -= 0.02;
        window.requestAnimationFrame(animate);
      }
    };
    animate();
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();