'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Split003 = function Split003() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $slider = document.querySelector('.split003-slider');

    if ($slider) {
      new Flickity($slider, {
        cellAlign: 'center',
        wrapAround: true,
        prevNextButtons: false,
        pageDots: true,
        imagesLoaded: true,
        adaptiveHeight: true,
        resize: true,
        groupCells: false,
        lazyLoad: true,
        autoPlay: 3500
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();