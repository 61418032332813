'use strict';

window.site = window.site || {};

/**
 * Utilities for Top bar Opening hours.
 * @class OpeningHours
 * @static
 */
site.OpeningHours = function OpeningHours() {

  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    var $regionSelect = document.querySelector('[action-topbar-region]');

    inited = true;

    var currentLocation = localStorage.getItem('currentLocation');

    if (currentLocation == null) {
      currentLocation = 'montréal';
    }

    var $locationElements = [].slice.call(document.querySelectorAll('.is-' + currentLocation));
    $regionSelect.value = currentLocation;

    $locationElements.forEach(function ($locationElement) {
      $locationElement.style.display = 'inline-block';
    });

    $regionSelect.addEventListener('change', function (e) {

      localStorage.setItem('currentLocation', $regionSelect.value);
      window.location.reload(false);
    });

    // Return success
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();