'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Hero = function Hero() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $heroSlider = document.querySelector('.hero-slider');
    var $hero = document.querySelector('.hero-grid001');

    /*if ($hero) {
      let heroHeight = $hero.offsetHeight;
       let bgHero = document.querySelector('.grid001-background');
       bgHero.style.height = heroHeight+'px';
       console.log(heroHeight);
    }*/

    if ($heroSlider) {
      setTimeout(function () {
        var flkty = new Flickity($heroSlider, {
          // options
          resize: true,
          cellAlign: 'center',
          contain: true,
          imagesLoaded: true,
          lazyLoad: true,
          wrapAround: true,
          pageDots: false,
          prevNextButtons: false,
          autoPlay: 5000
        });
      }, 200);
    }

    var $scrollTo = document.querySelector('.site-hero .mouse');

    if ($scrollTo) {
      $scrollTo.addEventListener('click', function (e) {
        e.preventDefault();

        var $zone = document.querySelector('.site-hero').nextElementSibling;
        var topPos = $zone.getBoundingClientRect().top + window.scrollY;

        // this changes the scrolling behavior to "smooth"
        window.scroll({
          top: topPos,
          behavior: "smooth"
        });
      });
    }

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();