'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.Content001 = function Content001() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    var $toggles = $('.toggler');

    $toggles.forEach(function (item) {

      item.addEventListener('click', function (e) {
        e.preventDefault();

        var $id = item.dataset.toggle;
        var $boxes = $('.our-mission[data-box]');

        $boxes.forEach(function (box) {

          var $boxId = box.dataset.box;
          var $toggle = $toggles.filter(function (toggle) {
            return toggle.dataset.toggle === $boxId;
          })[0];

          if ($boxId == $id) {

            item.classList.add('is-active');

            setTimeout(function () {
              box.classList.add('is-active');

              setTimeout(function () {
                box.classList.add('is-shown');
              }, 50);
            }, 600);

            document.querySelector('.block-mission').classList.add('vision-blue');
          } else {
            box.classList.remove('is-shown');

            document.querySelector('.block-mission').classList.remove('vision-blue');

            $toggle.classList.remove('is-active');

            setTimeout(function () {
              box.classList.remove('is-active');
            }, 600);
          }
        });
      });
    });

    inited = true;

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();