'use strict';

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.MenuMobile = function MenuMobile() {

  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $trigger = document.querySelector('.mobile-menu-trigger');
    var $triggerClose = document.querySelector('.navigation-close');

    if ($trigger && $triggerClose) {

      $trigger.addEventListener('click', function (e) {
        var $main = document.querySelector('.site-container');
        var $menu = document.querySelector('.navigation-mobile');

        $main.classList.toggle('active');
        $menu.classList.toggle('is-active');
      });

      $triggerClose.addEventListener('click', function (e) {
        var $main = document.querySelector('.site-container');
        var $menu = document.querySelector('.navigation-mobile');

        $main.classList.toggle('active');
        $menu.classList.toggle('is-active');
      });

      // Activate Follow Sticky
      activateMenuFollow();
    };

    return true;
  };

  var activateMenuFollow = function activateMenuFollow() {

    var $nav = document.querySelector('#main-header');
    var $container = document.querySelector('#site-container');
    var $form = document.querySelector('.form-vip-wrapper');
    var $fixedMenu = document.querySelector('.our-menu-nav');
    var buffer = 10;
    var $blocks = [].slice.call(document.querySelectorAll('.site-block'));
    var $lastBlock = $blocks ? $blocks[$blocks.length - 1] : null;
    var lastBlockBottom = $lastBlock ? $lastBlock.offsetParent.offsetTop + $lastBlock.offsetTop + $lastBlock.offsetHeight - 45 : null;

    var lastScrollTop = 0;

    var st = document.documentElement.scrollTop;

    if (st >= 158) {
      $nav.classList.remove('is-sticky');
      $container.classList.remove('is-sticky');
    } else {
      $nav.classList.remove('is-sticky');
      $container.classList.remove('is-sticky');
    }

    window.addEventListener("scroll", function () {

      var st = document.documentElement.scrollTop;

      // console.log(st);

      // console.log('Scroll pos : ' + (st + $nav.offsetHeight));
      // console.log('Nav Offset : ' + $nav.offsetTop);

      if (st >= 37) {
        $nav.classList.add('is-sticky');
        $container.classList.add('is-sticky');
      } else {
        $nav.classList.remove('is-sticky');
        $container.classList.remove('is-sticky');
      }

      if ($fixedMenu) {
        var topbarOffset = window.innerWidth <= 1024 ? 37 : 0;
        var fixedMenuTop = $fixedMenu.offsetTop - topbarOffset;

        if (st + $nav.offsetHeight > fixedMenuTop) {
          // downscroll code
          $nav.classList.add("hide-nav-bar");
          $container.classList.add("hide-nav-bar");
        } else if (st + $nav.offsetHeight < fixedMenuTop - buffer) {
          // upscroll code
          $nav.classList.remove("hide-nav-bar");
          $container.classList.remove("hide-nav-bar");
        }
      } else if (st > lastScrollTop) {
        // downscroll code
        $nav.classList.add("hide-nav-bar");
        $container.classList.add("hide-nav-bar");
      } else if (st < lastScrollTop - buffer) {
        // upscroll code
        $nav.classList.remove("hide-nav-bar");
        $container.classList.remove("hide-nav-bar");
      }

      if (st <= 15) {
        $nav.classList.remove("hide-nav-bar");
        $container.classList.remove("hide-nav-bar");
      }

      lastScrollTop = st;
    });

    //Add scroll manager for Form

    if ($form) {
      console.log('form exists');

      window.addEventListener('resize', function () {
        lastBlockBottom = $lastBlock.offsetParent.offsetTop + $lastBlock.offsetTop + $lastBlock.offsetHeight - 45;

        if ($form.classList.contains('is-absolute')) {
          $form.style.top = lastBlockBottom - $form.offsetHeight + 'px';
        }
      });

      window.addEventListener("scroll", function () {

        if (document.querySelector('body').getBoundingClientRect().width < 990) {
          console.log('in false');
          $form.classList.remove('is-absolute');
          return false;
        }

        var st = document.documentElement.scrollTop;

        var formBottom = $form.offsetTop + st + $form.offsetHeight;

        if ($form.getBoundingClientRect().top >= 226) {
          // console.log('offsetTop is good');

          $form.classList.remove('is-absolute');
          $form.style.top = '';
        } else if (formBottom >= lastBlockBottom && st >= $lastBlock.offsetParent.offsetTop + $lastBlock.offsetTop - $form.offsetHeight) {

          // console.log('no class and switching back');
          $form.classList.add('is-absolute');
          $form.style.top = lastBlockBottom - $form.offsetHeight + 'px';
        } else {
          // console.log('fully remove');
          $form.classList.remove('is-absolute');
          $form.style.top = '';
        }

        // console.log("-----START----");
        // console.log("Scroll Pos : " + st);
        // console.log("Last Block Offset Top : " + $lastBlock.offsetTop);
        // console.log("Last Block Offset with Parent :" + lastBlockBottom);
        // console.log("Last Block Offset Height : " + $lastBlock.offsetHeight);
        // console.log("Last Block Pos : " + lastBlockBottom);
        // console.log("Form Pos : " + formBottom);

        // console.log("Form Offset Top : " + $form.getBoundingClientRect().top);
        // console.log("Form Offset - Scroll Pos : " + (st - $form.offsetTop));
        // console.log("Form Offset Height : " + $form.offsetHeight);
        // console.log("Form Offset Bottom : " + ($form.offsetTop + st + $form.offsetHeight));
        // console.log("-----END----");
      });
    }
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();