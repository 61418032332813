'use strict';

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.scrollTo = function scrollTo() {

  'use strict';

  /**
     * jQuery elements
     * @private
     */

  /**
     * Has the class been initialized?
     * @private
     */

  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {

    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    var $scrollLinks = [].slice.call(document.querySelectorAll('[data-scroll-to]'));
    var $navLinks = [].slice.call(document.querySelectorAll('.fixed-nav-link'));
    var $viewportItems = [].slice.call(document.querySelectorAll('[data-in-viewport]'));
    var $sections = [].slice.call(document.querySelectorAll('.menu-blocks'));

    $scrollLinks.forEach(function (item) {

      item.addEventListener('click', function (e) {
        // alert('Click Event');
        e.preventDefault();

        var selector = item.getAttribute('href').replace('/', '');

        if (history.pushState) {
          history.pushState(null, null, selector);
        } else {
          location.hash = selector;
        }

        var offsetRemove = 0;

        if ($navLinks.length) {
          offsetRemove = document.querySelector('.menu-type-nav').offsetHeight;

          for (i = 0; i < $navLinks.length; i++) {
            $navLinks[i].classList.remove('is-active');

            if ($sections.length) {
              $sections[i].classList.remove('is-active');
            }
          }

          document.querySelector('.fixed-nav-link[href="' + selector + '"]').classList.add('is-active');

          if ($sections.length) {
            var $currentBlock = document.querySelector(selector);
            $currentBlock.classList.add('is-active');
          }
        }

        var $zone = document.querySelector(selector);

        var offsetForm = 0;

        if (selector == '#vipFormContainer') {
          offsetForm = 45;
        }

        var topPos = $zone.getBoundingClientRect().top + window.scrollY - offsetRemove - offsetForm;

        if (item.classList.contains('menu-link')) {
          $zone = document.querySelector('.hero-grid001');
          topPos = $zone.getBoundingClientRect().bottom + window.scrollY - offsetRemove - offsetForm;
        }

        console.log(topPos);

        // alert((topPos - 55));

        // this changes the scrolling behavior to "smooth"
        window.scroll({
          top: topPos,
          behavior: "smooth"
        });
      });
    });

    //Fixed Menu Scroll
    if ($navLinks.length) {

      $navLinks.forEach(function ($navLink) {

        $navLink.addEventListener('click', function (e) {
          e.preventDefault();

          var $currentBlock = document.querySelector($navLink.getAttribute('href'));
          var $sections = [].slice.call(document.querySelectorAll('.menu-blocks'));

          for (i = 0; i < $navLinks.length; i++) {
            $navLinks[i].classList.remove('is-active');
            $sections[i].classList.remove('is-active');
          }

          $currentBlock.classList.add('is-active');
          $navLink.classList.add('is-active');
        });
      });

      window.addEventListener('scroll', function () {
        if ($viewportItems) {
          $viewportItems.forEach(function ($viewPortItem) {

            if (isInViewport($viewPortItem)) {
              for (i = 0; i < $navLinks.length; i++) {
                $navLinks[i].classList.remove('is-active');
              }

              document.querySelector('.fixed-nav-link[href="#' + $viewPortItem.id + '"]').classList.add('is-active');
            };
          });
        }
      });
    };

    return true;
  };

  var isInViewport = function isInViewport(elem) {
    var bounding = elem.getBoundingClientRect();

    return bounding.top >= 0 && bounding.bottom <= (window.innerHeight + elem.offsetHeight / 2 || document.documentElement.clientHeight + elem.offsetHeight / 2);
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();